import { ReactElement, useState } from 'react';
import { Grant } from '../../store';
import { CabModal } from '@CabComponents/CabModal';
import { CabButton } from '@CabComponents/CabButton';
import { Box } from '@mui/material';

interface ComponentProps {
  grant?: Grant;
  signIn: () => Promise<undefined | { missingScopes: string[] }>
  onOAuthGranted: (providerId: number) => void;
  children: (value: {onClick: () => void}) => ReactElement
  PopUpContent: () => ReactElement
  provider: {id: number, name: string, label: string}
}

type Props = ComponentProps;

const OAuthButton = ({
  signIn, onOAuthGranted, children, PopUpContent, provider
}: Props): ReactElement => {
  const [missingScopes, setMissingScopes] = useState<string[]>([]);

  const onClick = async (): Promise<void> => {
    const response = await signIn();
    if (response?.missingScopes) {
      setMissingScopes(response.missingScopes);
    } else {
      onOAuthGranted(1);
    }
  };

  const handleRetry = () => {
    setMissingScopes([]);
    onClick();
  };

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      {children({onClick})}
      <CabModal
        open={missingScopes.length > 0}
        title="We're missing a few permissions"
        actionButtons={<>
          <CabButton onClick={handleRetry}>Try Again</CabButton>
          <CabButton buttonType="secondary" onClick={() => setMissingScopes([])}>Cancel</CabButton>
        </>}>
        <PopUpContent />
      </CabModal>
    </Box>
  );
};

export default OAuthButton;
